import * as React from 'react'
import FormContent from './FormContent';
import FormFieldsContent from './FormFieldsContent';
import FormFooter from './FormFooter';
import FormHeader from './FormHeader'

const Form = ({ children, onSubmit }: { children: React.ReactNode, onSubmit?: React.FormEventHandler<HTMLFormElement> }) => {
    return (
        <div
            className="flex flex-col items-start p-0 static top-0 left-0"
        >
            {/* EXTRACT: 1 - Form Form Wrapper ✔ */}
            <form
                className="flex-none order-1 flex-grow-none m-0 min-w-full"
                action="post"
                onSubmit={onSubmit}
            >
                {children}
            </form>
        </div>
    )
}
Form.Header = FormHeader;
Form.Content = FormContent;
Form.Fields = FormFieldsContent;
Form.Footer = FormFooter;
export default Form
