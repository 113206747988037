import { gql } from "@apollo/client";

export const LOGIN_LOCAL = gql`
    mutation LoginMutation($loginEmail: String!, $loginPassword: String!) {
        login(email: $loginEmail, password: $loginPassword)
    }
`;

export const LOGIN_WITH_MAGIC = gql`
    mutation MagicLoginMutation($loginEmail: String!, $token: String!) {
      magicLogin(email: $loginEmail, didToken: $token)
    }
`;