import * as React from 'react'

const FormFieldsContent = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            {/* EXTRACT: 4 - Form Fields Area ✔ */}
            <div className="flex flex-col items-start p-0 static top-4 left-4">
                <div className="flex-none order-none self-stretch flex-grow-0 my-6 mx-0">{/** margins here affect the height */}
                    {children}
                </div>
            </div>
            {/* End Form Fields Area */}
        </>
    )
}

export default FormFieldsContent
