import React, { useContext, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import { UserContext } from '../context/UserContext';
import * as magic from '../services/magic';

function Dashboard() {
    const { user: { email }, logoutUser, isLoadingSet, userSet } = useContext(UserContext);
    const history = useHistory();

    const handleLogOut = useCallback(async () => {
        try {
            await logoutUser().then(() => {
                history.push("/")
            });
        } catch (error: any) {
            console.error(error);
        }
    }, [history, logoutUser]);
    return (
        <div style={{ padding: "2rem" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <button onClick={handleLogOut}>
                    Sign Out
                </button>
            </div>
            <h1 className="h1">User: {email}</h1>
        </div>
    )
}

export default Dashboard
