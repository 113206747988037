import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

interface PrivateRouteProps extends RouteProps {
    component: any
}

function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
    const { user: { isLoggedIn } } = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={(props) => isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />}
        >

        </Route>
    )
}

export default PrivateRoute
