import * as React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { Form } from '../forms';
import { Input } from '../Input';

interface SubmittedArgs {
    email: string;
    password: string;
}

const LocalLogInForm = (
    { onSubmitted,
        disabled
    }: {
        onSubmitted?: (args: SubmittedArgs) => void,
        disabled?: boolean
    }) => {
    const [email, emailSet] = useState("");
    const [password, passwordSet] = useState("");
    const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();
        if (onSubmitted)
            onSubmitted({ email, password });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Header title={`Log In`} />
            <Form.Content>
                <Form.Fields>
                    <div id="form-inputs"
                        className="flex flex-col items-start p-0 static top-0 left-0"
                    >
                        <div className="flex-none order-none self-stretch flex-grow-0 my-4 mx-0">
                            <Input id="email" type="email" label="Email" placeholder="Email" name="email" onChange={(e) => {
                                e.persist();
                                emailSet(e.target.value);
                            }} value={email} />
                            <Input id="password" type="password" label="Password" placeholder="******" name="password" onChange={(e) => {
                                e.persist();
                                passwordSet(e.target.value);
                            }} value={password} />

                        </div>
                    </div>
                    <div id="actions">
                        <span className="flex items-center justify-end font-normal text-sm  text-gray-900 text-right">
                            <Link to="/forget-password" className="flex-none order-none self-stretch my-4">Forgot your password?</Link>
                        </span>
                        <Button onClick={handleSubmit}>Continue</Button>
                    </div>
                </Form.Fields>
                <Form.Footer>
                    Don't have an account? <Link to="/sign-up" className="font-semibold ml-1">Sign Up</Link>
                </Form.Footer>
            </Form.Content>
        </Form>
    )

};

export default LocalLogInForm
