import * as React from 'react';
import Container from './Container';

export const Wrapper = ({ children, ...rest }: { children: React.ReactNode; }) => {
    return <div className="relative w-full h-full">
        <div className="flex flex-col absolute w-full h-full left-0 top-0">
            <Container
                className="items-start p-0"
                innerContainer="order-none self-stretch flex-grow m-0"
            >
                {children}
            </Container>
        </div>
    </div>;
};
