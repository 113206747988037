import * as React from 'react';
import { InputProps } from './_types/InputProps';

export function Input({ id, label, ...rest }: InputProps) {
    return <div className="flex flex-row justify-center items-center p-4 static top-0 left-0 bg-gray-100 rounded my-2 mx-0">
        <label htmlFor={id}
            className="text-xs w-3/12"
        >
            {label}
        </label>

        <div className="flex flex-row justify-start items-center p-0 static top-4 w-full">
            <input
                className="flex-none order-1 self-stretch flex-grow-1 my-0 mx-2 bg-gray-100 text-gray-900 text-xs w-full"
                id={id}
                {...rest} />
        </div>
    </div>;
}
