import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from "react-router-dom";
import logo from '../assets/images/logo.svg';
// import '../assets/styles/App.css';
import { UserContext } from '../context/UserContext';
import { HELLO_QUERY } from '../graphql/Queries';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Landing from '../pages/LoginLanding';
import Authenticate from '../pages/Authenticate';
import Callback from './Callback';
import PrivateRoute from './PrivateRoute';

function App() {
  const { loading, data, error } = useQuery(HELLO_QUERY);
  const { user, isLoggingIn } = useContext(UserContext)



  if (isLoggingIn) {
    return (<div className="h-screen flex justify-center items-center"><span>Loading....</span></div>);
  }
  return (

    <Router>
      {user.isLoggedIn && <Redirect to={{ pathname: '/dashboard' }} />}
      <div className="App">
        {user && user.isLoggedIn && (<header className="flex flex-row" >
          <nav className="w-full">
            <ul className="flex flex-row gap-1 list-none justify-between">
              <li className="flex flex-row">
                <ul className="flex flex-row gap-2 list-none justify-between">
                  <li>
                    <Link to="/home">home</Link>
                  </li>
                  <li>
                    <Link to="/about">about</Link>
                  </li>
                  <li>
                    <Link to="/contact">contact us</Link>
                  </li>
                </ul>
              </li>
              <li className="flex flex-row gap-1 pr-10" >
                <Link to="/">login</Link>
              </li>
            </ul>
          </nav>
        </header>)}
        <main className="my-0 mx-auto">
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={Authenticate} />
            <Route path="/callback" exact component={Callback} />
            <Route path="/home" exact render={(props) => (
              <div>
                <h1>Home</h1>
                <img src={logo} className="App-logo" alt="logo" />
                {error ? (<pre>{JSON.stringify(error, null, 2)}</pre>) : null}
                {loading ? <p>"Loading..."</p> : (<p>
                  {data && data.hello}
                </p>)}
              </div>
            )} />
            <Route path="/about" exact render={() => (<h1>about</h1>)} />
            <Route path="/contact" exact render={(props) => (<h1>contact</h1>)} />
            <Route path="/login2" exact component={Login} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
