import { useMutation } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN_WITH_MAGIC } from '../graphql/Mutations';
import * as magic from '../services/magic';

type UserState = { isLoggedIn: boolean; email: string };
//TODO: Change default state to an interface
const defaultUserState = {
    user: { isLoggedIn: false, email: '' },
    userSet: (state: UserState) => { },
    loginUser: async (email: string) => { return "" },
    logoutUser: async () => { },
    isLoggingIn: false,
    isLoadingSet: (state: boolean) => { }
};
const UserContext = createContext(defaultUserState);



const UserProvider = ({ children }: {
    children: React.ReactNode
}) => {
    const [user, userSet] = useState(defaultUserState.user);
    const [isLoading, isLoadingSet] = useState(false);
    const history = useHistory();
    // const {}  = useMutation()
    /**
     * Attempts to login in the user and save the email to context
     * @param {string} email 
     * 
     */
    const loginUser = async (email: string) => {
        try {
            const did = await magic.loginUser(email);

            userSet({ isLoggedIn: true, email });
            return did!;
            // history.push('/dashboard');

        } catch (error) {
            userSet({ isLoggedIn: false, email: '' });
            // history.replace('/');
            return "";
        }
    };

    /**
     * Logs out the user and resets the user information
     */
    const logoutUser = async () => {
        try {
            await magic.logoutUser();
            userSet({ isLoggedIn: false, email: '' });
            history.replace('/');
        } catch (error) {
            console.log('Unable to logout', error)
        }

    }

    useEffect(() => {
        const validateUser = async () => {
            isLoadingSet(true);
            try {
                await magic.checkUser(userSet);
                isLoadingSet(false);
            } catch (e: any) {
                history.replace('/');
                console.error(e);
            }
        };
        validateUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const validateUser = async () => {
            isLoadingSet(true);
            try {
                await magic.checkUser(userSet);
                isLoadingSet(false);
            } catch (e: any) {
                console.error(e);
            }
        };
        validateUser();

    }, [user.isLoggedIn, userSet]);
    return (
        <UserContext.Provider value={{ user, loginUser, logoutUser, userSet, isLoggingIn: isLoading, isLoadingSet }}>
            {children}
        </UserContext.Provider >
    )
};

export { UserContext, UserProvider }