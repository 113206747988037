import { Magic } from 'magic-sdk';
export const magic = new Magic(process.env.REACT_APP_MAGIC_PK!);

//TODO: Wrap `checkUser` with try..catch block
export const checkUser = async (callback: Function) => {
  const isLoggedIn = await magic.user.isLoggedIn();
  if (isLoggedIn) {
    const user = await magic.user.getMetadata();
    return callback({ isLoggedIn: true, email: user.email });
  }
  return callback({ isLoggedIn: false });
};

//TODO: Wrap `loginUser` with try..catch block
export const loginUser = async (email: string) => {
  return magic.auth.loginWithMagicLink({
    email,
    redirectURI: new URL("/callback", window.location.origin).href,
  });
};

//TODO: Wrap `logoutUser` with try..catch block
export const logoutUser = async () => {
  return magic.user.logout();
};