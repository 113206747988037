import React from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {

    return (
        <div className="flex flex-col items-start p-2.5 static left-0 top-0 w-full h-screen">
            <div className="flex-none order-1 self-stretch flex-grow my-2.5 mx-0">
                <div className="min-h-full flex flex-col">
                    <div className="flex flex-col items-center justify-center p-0 static w-[362px] h-[349px] left-4 right-4">
                        <div className="flex-none order-none flex-grow-0 my-6 mx-0 flex flex-col justify-center items-center">
                            <div className="static top-0  w-60 h-60 bg-gray-900 border rounded-full">
                                <div className="flex-none order-none flex-grow-0 my-6 mx-0">
                                    {/* <img src="https://place-hold.it/240x240/111827/fff" className="static top-0 left-14 w-60 h-60 bg-gray-900 border flex-none order-none flex-grow-0 my-6 mx-0 rounded-full" alt="alt" /> */}
                                </div>
                            </div>
                            <div className="flex flex-col items-start static w-[414px]  top-[265px] -left-6">
                                <div className="flex-none order-none flex-grow-0 my-6 mx-0 text-center">
                                    <h1 className="font-bold text-4xl capitalize text-gray-900 ">partna draw</h1>
                                    <p className="font-normal text-2xl leading-7 text-gray-900 flex-none order-1 self-stretch flex-grow-0 my-4 mx-0" >Earn money with group savings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-start w-full p-0 mt-auto">
                        <div className={`flex-none order-1 items-stretch flex-grow-0 ${/*`my-6`*/''} mx-0 w-full`}>
                            <div className="w-full">
                                <Link to="/login2" className="flex items-center justify-center py-3 px-4 h-11 w-full bg-gray-900 text-white rounded-lg font-medium leading-5 text-center">
                                    <span className="bg-white w-5 h-5 rounded-full my-0 mx-2"></span>    Sign up with Email
                                </Link>
                                <Link to="/login" className="flex items-center justify-center py-3 px-4 h-11 w-full bg-gray-200 text-gray-900 rounded-lg my-4 mx-0 font-medium leading-5 text-center">
                                    <span className="bg-gray-900 w-5 h-5 rounded-full my-0 mx-2"></span>Sign up with Magic
                                </Link>
                            </div>
                            <div className={`flex items-center justify-center font-normal text-xs leading-tight text-center text-gray-900 mx-0 ${/*`my-6`*/''}`}>
                                Already have an account? <Link to="/login" className="font-semibold ml-1">Sign In</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Landing