import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { magic } from "../services/magic";

export default function Callback() {
    const history = useHistory();

    useEffect(() => {
        // localStorage.setItem("SearchToken", window.location.search);
        magic.auth.loginWithCredential().finally(() => {
            history.push('/');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        
    }, []);

    return <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}><span>Loading....</span></div>
}