import * as React from 'react'
import { Header } from '../Header'

const FormHeader = ({ title, subTitle }: { title: string, subTitle?: string }) => {
    return (
        <>
            {/* EXTRACT: 2 - Form Header Area ✔ */}
            < div className="flex flex-col justify-center items-start p-4" >
                <Header title={title} />
                {/* Added subtitle here */}
                {subTitle && (<span>{subTitle}</span>)}
            </div >
            {/* End Form Header Area */}
        </>
    )
}

export default FormHeader
