import React from 'react'

const Container = ({ children, className, innerContainer, ...rest }: { children: React.ReactNode, className?: string, innerContainer?: string }) => {
    return (
        <div className={`h-screen flex flex-col static left-0 top-0 w-full ${className ?? ''}`}>
            <div className={`flex-none ${innerContainer ?? ''}`}>
                {children}
            </div>
        </div>

    )
}

export default Container
