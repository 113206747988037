import * as React from 'react'

const FormFooter = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            {/* EXTRACT: 5 - Form Footer ✔ */}
            <div className="flex items-center justify-center font-normal text-xs leading-tight text-center text-gray-900 my-6 mx-0">
                {children}
            </div>
            {/* End Form Footer */}
        </>
    )
}

export default FormFooter
