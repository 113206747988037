import * as React from 'react'

const FormContent = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            {/* EXTRACT: 3 - Form Body/Content Area ✔ */}
            <div className="flex flex-col justify-center items-start p-4">
                <div className="flex-none order-1 flex-grow-0 m-0 w-full">
                    {children}
                </div>
            </div>
            {/* End Form Body/Content Area */}
        </>
    )
}

export default FormContent
