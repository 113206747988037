import * as React from 'react';
import LocalLogInForm from '../components/app/LocalLogInForm';
import { Page } from '../components/layouts/Page';


const Login = () => {
    return (
        <Page>
            <LocalLogInForm onSubmitted={({ email }) => console.log(email)} disabled={false} />
        </Page>
    )
}



export default Login
