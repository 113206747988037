import { useMutation } from '@apollo/client';
import React, { useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import EmailOnlyLogInForm from '../components/app/EmailOnlyLogInForm';
import { Page } from '../components/layouts/Page';
import { UserContext } from '../context/UserContext';
import { LOGIN_WITH_MAGIC } from '../graphql/Mutations';

const Authenticate = () => {
    const { loginUser } = useContext(UserContext);
    const [email, emailSet] = useState('');
    const [loading, loadingSet] = useState(false);
    const [error, errorSet] = useState('');
    const history = useHistory();
    const [loginWithMagic, { loading: apolloLoading, error: apolloError }] = useMutation(LOGIN_WITH_MAGIC)
    const handleSubmit = useCallback(async (args) => {

        loadingSet(true);
        if (!email) {
            loadingSet(false);
            errorSet('Email is Invalid');
            return;
        }
        try {
            const did = await loginUser(email);
            loginWithMagic({ variables: { loginEmail: email, token: did } })
            loadingSet(apolloLoading);
            history.push('/dashboard');
        } catch (e: any) {
            emailSet('');
            loadingSet(false);
            errorSet(apolloError?.message || 'Unable to log in');
            console.error(e);
            history.replace('/');
        }

    }, [apolloError?.message, apolloLoading, email, history, loginUser, loginWithMagic]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        emailSet(e.target.value)
    }, []);

    return (
        <Page>
            <EmailOnlyLogInForm
                email={email}
                disabled={loading}
                error={error}
                onSubmitted={handleSubmit}
                onChange={handleChange} />
        </Page>
    );
};

export default Authenticate;