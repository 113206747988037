import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { Form } from '../forms';
import { Input } from '../Input';

interface SubmittedArgs {
    email: string;
}

const EmailOnlyLogInForm = ({ onSubmitted, email, error, onChange, disabled }: { email: string, error?: string, disabled?: boolean, onSubmitted?: (args: SubmittedArgs) => void, onChange: React.ChangeEventHandler<HTMLInputElement> }) => {
    const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();
        if (onSubmitted)
            onSubmitted({ email });
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Header title={`Log In`} />
            <Form.Content>
                <Form.Fields>
                    <div id="form-inputs"
                        className="flex flex-col items-start p-0 static top-0 left-0"
                    >
                        <div className="flex-none order-none self-stretch flex-grow-0 my-4 mx-0">
                            <Input
                                id="email"
                                type="email"
                                label="Email"
                                placeholder="Email"
                                name="email"
                                onChange={onChange}
                                required
                                disabled={disabled}
                                value={email} />
                            {error ?? (<span className="font-normal text-red-500 text-sm">{error}</span>)}
                        </div>
                    </div>
                    <div id="actions">
                        <span className="flex items-center justify-end font-normal text-sm  text-gray-900 text-right">
                            <Link to="/forget-password?flow=email" className="flex-none order-none self-stretch my-4">Forgot your email?</Link>
                        </span>
                        <Button disabled={disabled}>{(disabled ? 'Loading...' : 'Continue')}</Button>
                    </div>
                </Form.Fields>
                <Form.Footer>
                    Don't have an account? <span className="font-semibold ml-1">Don't worry we will create one!</span>
                </Form.Footer>
            </Form.Content>
        </Form>
    )
}

export default EmailOnlyLogInForm
